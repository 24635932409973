import React, { useEffect } from 'react';
import { Parallax } from 'react-parallax';

const lockImage = require('../assets/lockImage1.png'); // Import the image

const Privacy = () => {
  useEffect(() => {
    document.body.style.margin = 0;
    document.body.style.padding = 0;
    document.body.style.height = '100vh';
    document.body.style.background = 'linear-gradient(to bottom, #232526, #414345)';
  }, []);

  const styles = {
    container: {
      width: '80vw',
      maxWidth: '800px',
      margin: '0 auto',
      padding: '40px',
      fontFamily: '"Poppins", Arial, sans-serif',
      lineHeight: '1.8',
      backgroundColor: 'rgba(12, 12, 12, 0.8)',
      borderRadius: '15px',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
      color: '#fff',
      minHeight: '80vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
      overflow: 'hidden',
      zIndex: 1,
    },
    heading: {
      textAlign: 'center',
      marginBottom: '0px',
      color: '#fff',
      fontSize: '2.5rem',
      fontWeight: '600',
      letterSpacing: '2px',
      position: 'relative',
      zIndex: 2,
      animation: 'fadeIn 1.2s ease-in-out',
    },
    subheading: {
      marginTop: '10px',
      marginBottom: '10px',
      color: '#00bcd4',
      fontSize: '1.4rem',
      fontWeight: 'bold',
      cursor: 'pointer',
      transition: 'color 0.3s ease',
    },
    paragraph: {
      marginTop: '10px',
      marginBottom: '15px',
      color: '#ddd',
      fontSize: '1.1rem',
    },
    divider: {
      height: '2px',
      backgroundColor: '#555',
      margin: '20px 0',
    },
    contactSection: {
      marginTop: '30px',
      paddingTop: '20px',
      borderTop: '2px solid #555',
      textAlign: 'center',
    },
    contactLink: {
      color: '#00bcd4',
      textDecoration: 'none',
      cursor: 'pointer',
      fontWeight: 'bold',
      transition: 'color 0.3s ease',
    },
    parallaxContainer: {
      height: '300px',
      position: 'relative',
      zIndex: 1,
    },
    parallaxBackground: {
      height: '100%',
      width: '100%',
    },
    '@keyframes fadeIn': {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    image: {
      width: '100%',
      height: '300px',
      objectFit: 'cover',
    },
  };

  return (
    <div style={styles.container}>
      <Parallax
        bgImage={lockImage} // Use the image
        strength={300}
        style={styles.parallaxContainer}
      >
        <div style={styles.parallaxBackground}></div>
      </Parallax>
      <h1 style={styles.heading}>Privacy Policy</h1>
      <p style={styles.paragraph}>
        We are committed to safeguarding your privacy. This Privacy Policy explains how we collect, use, and protect your personal information when you use our app.
      </p>
      <div style={styles.divider}></div>
      <h2 style={styles.subheading}>Location Data</h2>
      <p style={styles.paragraph}>
        We use your location data solely to provide you with relevant and engaging content based on your proximity to posts. Rest assured, we do not store or share your location with any third parties.
      </p>
      <h2 style={styles.subheading}>Phone Number</h2>
      <p style={styles.paragraph}>
        Your phone number is used exclusively for login purposes. We do not utilize it for any other reasons, nor do we share it with external parties.
      </p>
      <h2 style={styles.subheading}>Data Security</h2>
      <p style={styles.paragraph}>
        Your data's security is our top priority. We implement robust security measures to protect your information from unauthorized access and ensure its safe handling.
      </p>
      <h2 style={styles.subheading}>Contact Us</h2>
      <p style={styles.paragraph}>
        If you have any questions or concerns about our privacy practices, please reach out to our support team. We are here to ensure your privacy and provide a secure experience.
      </p>
      <div style={styles.contactSection}>
        <p>
          Contact our support team at <span style={styles.contactLink}>support@destinyy.in</span>
        </p>
      </div>
    </div>
  );
}

export default Privacy;
