import React, { useState } from "react";
import styles from "./About.module.css";
import cursorIcon from "../../assets/about/cursorIcon.png";
import serverIcon from "../../assets/about/serverIcon.png";
import uiIcon from "../../assets/about/uiIcon.png";

export const About = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <section className={styles.container} id="about">
      <h2 className={styles.title}>What does Destiny offer you?</h2>
      <div className={styles.content}>
        <ul className={styles.aboutItems}>
          <li className={styles.aboutItem}>
            <img src={cursorIcon} alt="Cursor icon" />
            <div className={styles.aboutItemText}>
              <button onClick={toggleExpanded}>
                Post updates and share experiences
              </button>
              <p>You can post and see posts nearby you...</p>
            </div>
          </li>
          <li className={styles.aboutItem}>
            <img src={serverIcon} alt="Server icon" />
            <div className={styles.aboutItemText}>
              <button onClick={toggleExpanded}>
                Connect with people in your vicinity
              </button>
               <p>You can connect with people, message them...</p>
            </div>
          </li>
          <li className={styles.aboutItem}>
            <img src={uiIcon} alt="UI icon" />
            <div className={styles.aboutItemText}>
              <button onClick={toggleExpanded}>Earn exciting offers</button>
               
                <p>
                  Earn Streaks based on your engagement and Receive special
                  offers from local businesses..
                </p>
              
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};
