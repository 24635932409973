import React from "react";
import styles from "./DownloadSection.module.css";
import { getImageUrl } from "../../helpers/utils";

const DownloadSection = () => {
  return (
    <div className={styles.downloadSection}>
      <h2>Download Now and Get Started Immediately!</h2>
      <p>
        Click the below button to download the App from AppStore and PlayStore.
      </p>
      <div className={styles.buttons}>
        <a href="https://www.apple.com/app-store/" className={styles.button}>
          <img
            src={require("../../assets/DownloadSection/applelogo.png")}
            alt="Apple Store"
            className={styles.icon}
          />
          App Store
        </a>
        <a href="https://play.google.com/store" className={styles.button}>
          <img
            src={require("../../assets/DownloadSection/playstore.png")}
            alt="Play Store"
            className={styles.icon}
          />
          Play Store
        </a>
      </div>
    </div>
  );
};

export default DownloadSection;
