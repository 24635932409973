import React from "react";
import styles from "../App.module.css";
import { WelcomePage } from "../components/WelcomePage/WelcomePage";
import { About } from "../components/About/About";
import { Contact } from "../components/Contact/Contact";
import DownloadSection from "../components/DownloadSection/DownloadSection";

function Welcome() {
  return (
    <div className={styles.App}>
      <WelcomePage />
      <About />
      <DownloadSection />
      <Contact />
    </div>
  );
}

export default Welcome;
