import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

// Inline CSS for simplicity
const styles = {
  body: {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    fontFamily: '"Poppins", Arial, sans-serif',
  },
  container: {
    width: '80vw',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '40px',
    fontFamily: '"Poppins", Arial, sans-serif',
    lineHeight: '1.8',
    backgroundColor: 'rgba(12, 12, 12, 0.8)',
    borderRadius: '15px',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
    color: '#fff',
    minHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    zIndex: 1,
  },
  heading: {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '28px',
    fontWeight: 'bold',
    color: '#f8f9fa',
  },
  formGroup: {
    marginBottom: '20px',
  },
  label: {
    display: 'block',
    marginBottom: '8px',
    fontSize: '18px',
    color: '#f8f9fa',
  },
  input: {
    width: '100%',
    padding: '12px',
    border: '1px solid #444',
    borderRadius: '8px',
    fontSize: '16px',
    color: '#000',
    backgroundColor: '#fff',
    transition: 'border-color 0.3s ease',
  },
  inputFocus: {
    borderColor: '#007bff',
  },
  textarea: {
    width: '100%',
    padding: '12px',
    border: '1px solid #444',
    borderRadius: '8px',
    fontSize: '16px',
    resize: 'vertical',
    color: '#000',
    backgroundColor: '#fff',
    transition: 'border-color 0.3s ease',
  },
  textareaFocus: {
    borderColor: '#007bff',
  },
  button: {
    width: '100%',
    padding: '12px',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: '#007bff',
    color: '#fff',
    fontSize: '18px',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  buttonHover: {
    backgroundColor: '#0056b3',
  },
  buttonActive: {
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
  },
};

export default function Contact() {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isHovered, setIsHovered] = useState(false);
  const [focusField, setFocusField] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_brk9p5j', 'template_3ansfz9', form.current, 'AHFNpZ-iOiXEtF8_l')
      .then(
        () => {
          console.log('SUCCESS!');
          alert('Message sent!');
          setFormData({ name: '', email: '', message: '' });
        },
        (error) => {
          console.log('FAILED...', error.text);
          console.error('Error:', error.text); // Log error details
          alert('Failed to send message. Please try again.');
        }
      );
  };

  const handleFocus = (e) => {
    setFocusField(e.target.name);
  };

  const handleBlur = () => {
    setFocusField(null);
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Contact Us</h2>
      <form ref={form} onSubmit={handleSubmit}>
        <div style={styles.formGroup}>
          <label htmlFor="name" style={styles.label}>Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            required
            style={{ ...styles.input, ...(focusField === 'name' ? styles.inputFocus : {}) }}
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="email" style={styles.label}>Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            required
            style={{ ...styles.input, ...(focusField === 'email' ? styles.inputFocus : {}) }}
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="message" style={styles.label}>Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            rows="4"
            required
            style={{ ...styles.textarea, ...(focusField === 'message' ? styles.textareaFocus : {}) }}
          />
        </div>
        <button
          type="submit"
          style={{
            ...styles.button,
            ...(isHovered ? styles.buttonHover : {}),
            ...(focusField ? styles.buttonActive : {}),
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          Send Message
        </button>
      </form>
    </div>
  );
}