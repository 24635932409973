import React from "react";
import styles from "./App.module.css";
import NavBar from "./components/NavigationBar/NavigationBar";

import Welcome from "./screens/Welcome";
import { Route, Routes } from "react-router-dom";
import Contact from "./screens/Contact";
import Privacy from "./screens/Privacy";
import PostView from "./screens/PostView";

function App() {
  return (
    <div className={styles.App}>
      <NavBar />

     <br/>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/PostView/:postId" element={<PostView />} />
      </Routes>
    
    </div>
  );
}

export default App;
