/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

import styles from "./WelcomePage.module.css";
import { getImageUrl } from "../../helpers/utils";



export const WelcomePage = () => {
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>Welcome to Destiny</h1>
        <p className={styles.description}> ~ connecting your vicinity!</p>
      </div>
   
      <img
        src={require("../../assets/WelcomePage/DestinyLogo.png")}
        alt="Hero image of me"
        className={styles.WelcomePageImg}
      />
      <div className={styles.topBlur} />
      <div className={styles.bottomBlur} />
    </section>
  );
};
